import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Chicago Culinary Kitchen",
  pageDescription:
    "See how a popular barbecue joint in Illinois expanded its email marketing and customer base using SpotOn technology.",
  title: "Boosting traffic to a BBQ business",
  subTitle: `How Chicago Culinary Kitchen markets to guests with SpotOn.`,
  businessName: "Chicago Culinary Kitchen",
  industry: "Restaurants & hospitality, casual dining",
  location: "Palatine, Illinois",
  products: [
    spotonProducts.RESTAURANT,
    spotonProducts.ORDER,
    spotonProducts.GIFT_CARDS,
  ],
  description: `Rated the <a href="https://money.com/best-barbecue-us-every-state/" target="_blank">best BBQ in Illinois by Money</a>, The Chicago Culinary Kitchen (CCK) is a catering company, food truck purveyor, and in-house barbecue spot all rolled into one. And with SpotOn, operations manager Jay Goldberg has not only found a tech platform but a marketing partner as well.`,
  quoteData: {
    imgName: "chicago-culinary-kitchen.png",
    quote: `“Everybody has an email list. Some see it as a throwaway. But for me, every email is an investment. I use SpotOn Marketing every day. Every person that joins our list is someone who wants to spend money with us, so I want to keep them engaged.”`,
    personName: "Jay Goldberg",
    personTitle: "Operations Manager, Chicago Culinary Kitchen",
  },
  goal: `Goldberg was looking to streamline the ordering process and expand from counter service to online ordering. Plus, he wanted to email guests with menu updates and specials by email, encouraging repeat visits.`,
  solution: `With SpotOn Restaurant, CCK can route items to multiple kitchen stations and make daily menu changes. Guests enjoy the convenience of online ordering, so much so that they have had to implement order pacing to keep up with weekend demand. SpotOn’s marketing tools allow CCK to capture emails, offer gift cards, and send regular promotions.`,
  // solutionReadMore: {
  //   text: `Read more about their story.`,
  //   url: `https://spoton.com/blog/chiva-loka-spoton-capital-funds/`,
  // },
  results: {
    title: `The results`,
    stats: [
      { title: `7%`, description: `increase in sales` },
      {
        title: `3,000`,
        description: `guests on their email list`,
      },
      { title: `50%`, description: `open rate` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Beer, beef, and baseball",
    imageName: "von-elrods-beer-hall-and-kitchen.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-02-21",
    href: "/case-studies/von-elrods-beer-hall-and-kitchen",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
  {
    title: "Steak and tech done to perfection",
    imageName: "deblaze-grill.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-07",
    href: "/case-studies/deblaze-grill",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Coordinating a Caribbean restaurant",
    imageName: "freetown-road-project.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-28",
    href: "/case-studies/freetown-road-project",
    goals: [
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
];
